var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',[_c('v-card-title',[_c('div',{staticClass:"page-title text-center pb-1 mx-auto"},[_c('h2',{staticClass:"font-weight-semibold text--primary d-flex align-center justify-center"},[_c('span',{staticClass:"me-2"},[_vm._v(" Your Past Winnings ")])])])]),_c('div',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.claims,"item-key":"index","loading":_vm.loadingTable,"loading-text":"Loading...","sort-by":"created","sort-desc":true},scopedSlots:_vm._u([{key:"item.claimType",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"pa-4"},[_c('h4',[_vm._v(_vm._s(item.event))]),_c('span',[_c('i',[_vm._v(_vm._s(item.desc))])]),_c('br'),_vm._v(" ("),(item.claimType=='skc')?_c('small',[_vm._v("Silver KombatCoins")]):(item.claimType=='gkc')?_c('small',[_vm._v("Gold KombatCoins")]):_vm._e(),(item.claimType=='fighter')?_c('small',[_vm._v("Fighter Pack")]):_vm._e(),_vm._v(") ")])]}},{key:"item.silverCoins",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("toCurrencyNoDec")(item.silverCoins))+" ")]}},{key:"item.goldCoins",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("toCurrency")(item.goldCoins))+" ")]}},{key:"item.fighter",fn:function(ref){
var item = ref.item;
return [(item.fighter)?_c('span',[_vm._v(" "+_vm._s(_vm._f("toCurrencyNoDec")(item.fighter.value))+" ")]):_c('span',[_vm._v("0")])]}},{key:"item.created",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"pa-4"},[_vm._v(" "+_vm._s(_vm._f("moment")(item.created.seconds,"Do MMM YYYY"))+" ")])]}},{key:"item.claimedDate",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-center"},[(item.claimedDate)?_c('div',{staticClass:"pa-4"},[_vm._v(" "+_vm._s(_vm._f("moment")(item.claimedDate.seconds,"Do MMM YYYY"))+" ")]):_c('div',[_vm._v(" Un-claimed!"),_c('br'),_c('router-link',{attrs:{"to":"/claim-winnings"}},[_c('v-btn',{attrs:{"outlined":"","small":"","color":"success"}},[_vm._v(" Claim Winnings ")])],1)],1)])]}}])},[_c('template',{slot:"body.append"},[_c('tr',[_c('th',[_vm._v("Total")]),_c('th',[_vm._v(_vm._s(_vm._f("toCurrencyNoDec")(_vm.silverTotal)))]),_c('th',[_vm._v(_vm._s(_vm._f("toCurrency")(_vm.goldTotal)))]),_c('th'),_c('th')]),_c('tr',{staticClass:"d-none"},[_c('th',[_vm._v("Approximate Value")]),_c('th',[_vm._v(" USD: $"+_vm._s(_vm._f("twoDecimal")(_vm.silverToUSD))),_c('br'),_vm._v(" SOL: "+_vm._s(_vm._f("twoDecimal")(_vm.silverToSOL))+" ")]),_c('th',[_vm._v(" USD: "+_vm._s(_vm._f("twoDecimal")(_vm.goldToUSD))),_c('br'),_vm._v(" SOL: "+_vm._s(_vm._f("twoDecimal")(_vm.goldToSOL))+" ")]),_c('th'),_c('th')])])],2),_c('div',{staticClass:"pa-3"},[_c('small',[_vm._v("* Silver KombatCoins can't be exchanged directly to USD / Gold KombatCoins."),_c('br'),_vm._v(" * Fighters recruited using SKC can be traded on the marketplace.")])])],1),(!_vm.claims.length > 0 && !_vm.loadingTable)?_c('p',{staticClass:"text-center mb-5 pb-8"},[_vm._v(" No past claims. ")]):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }