<template>
  <div>
    <v-card>
      <v-card-title>
        <div class="page-title text-center pb-1 mx-auto">
          <h2 class="font-weight-semibold text--primary d-flex align-center justify-center">
            <span class="me-2">
              Your Past Winnings
            </span>
          </h2>
        </div>
      </v-card-title>
      <div>
        <v-data-table
          :headers="headers"
          :items="claims"
          item-key="index"
          :loading="loadingTable"
          loading-text="Loading..."
          sort-by="created"
          :sort-desc="true"
        >
          <template v-slot:item.claimType="{ item }">
            <div class="pa-4">
              <h4>{{ item.event }}</h4>
              <span><i>{{ item.desc }}</i></span>
              <br>
              (<small v-if="item.claimType=='skc'">Silver KombatCoins</small>
              <small v-else-if="item.claimType=='gkc'">Gold KombatCoins</small>
              <small v-if="item.claimType=='fighter'">Fighter Pack</small>)
            </div>
          </template>

          <template v-slot:item.silverCoins="{ item }">
            {{ item.silverCoins|toCurrencyNoDec }}
          </template>
          <template v-slot:item.goldCoins="{ item }">
            {{ item.goldCoins|toCurrency }}
          </template>
          <template v-slot:item.fighter="{ item }">
            <span v-if="item.fighter">
              {{ item.fighter.value|toCurrencyNoDec }}
            </span>
            <span v-else>0</span>
          </template>
          <template v-slot:item.created="{ item }">
            <div class="pa-4">
              {{ item.created.seconds|moment("Do MMM YYYY") }}
            </div>
          </template>
          <template v-slot:item.claimedDate="{ item }">
            <div class="text-center">
              <div
                v-if="item.claimedDate"
                class="pa-4"
              >
                {{ item.claimedDate.seconds|moment("Do MMM YYYY") }}
              </div>
              <div v-else>
                Un-claimed!<br>
                <router-link to="/claim-winnings">
                  <v-btn
                    outlined
                    small
                    color="success"
                  >
                    Claim Winnings
                  </v-btn>
                </router-link>
              </div>
            </div>
          </template>
          <template slot="body.append">
            <tr>
              <th>Total</th>
              <th>{{ silverTotal|toCurrencyNoDec }}</th>
              <th>{{ goldTotal|toCurrency }}</th>
              <th></th>
              <th></th>
            </tr>
            <tr class="d-none">
              <th>Approximate Value</th>
              <th>
                USD: ${{ silverToUSD|twoDecimal }}<br>
                SOL: {{ silverToSOL|twoDecimal }}
              </th>
              <th>
                USD: {{ goldToUSD|twoDecimal }}<br>
                SOL: {{ goldToSOL|twoDecimal }}
              </th>
              <th></th>
              <th></th>
            </tr>
          </template>
        </v-data-table>
        <div class="pa-3">
          <small>* Silver KombatCoins can't be exchanged directly to USD / Gold
            KombatCoins.<br>
            * Fighters recruited using SKC can be traded on the marketplace.</small>
        </div>
      </div>
      <p
        v-if="!claims.length > 0 && !loadingTable"
        class="text-center mb-5 pb-8"
      >
        No past claims.
      </p>
    </v-card>
  </div>
</template>

<script>
import firebase from 'firebase/app'
import 'firebase/auth'
import dbFunctions from '@/db'

export default {
  data() {
    return {
      loadingTable: true,
      claims: [],
      currencies: [],
      silverTotal: 0,
      silverToUSD: 0,
      silverToSOL: 0,
      goldTotal: 0,
      goldToUSD: 0,
      goldToSOL: 0,
      toSol: 0,
      toUSD: 0,
      user: this.$store.state.user,
      headers: [
        {text: 'Prize', value: 'claimType'},
        {text: 'Silver KombatCoins', value: 'silverCoins'},
        {text: 'Gold KombatCoins', value: 'goldCoins'},
        {text: 'Date Won', value: 'created'},
        {text: 'Date Claimed', value: 'claimedDate'},
      ],
    }
  },
  mounted() {
    this.getCurrencies()
    this.checkClaims()
  },
  methods: {
    async checkClaims() {
      await dbFunctions.getPrizeList(this.user.uid, true)
        .then(data => {
          this.claims = data
          const starterpack = {
            userUid: this.user.uid,
            claimType: 'skc',
            event: 'Starter Packs',
            desc: 'Early sign-up bonus',
            silverCoins: this.user.profile.freeSilverKombatCoins,
            goldCoins: 0,
            created: {seconds: new Date(firebase.auth().currentUser.metadata.creationTime).getTime()},
            claimedDate: {seconds: new Date(firebase.auth().currentUser.metadata.creationTime).getTime()},
          }
          this.claims.push(starterpack)
          for (let i = 0; i < this.claims.length; i += 1) {
            if (this.claims[i].claimType === 'skc') {
              this.silverTotal += this.claims[i].silverCoins
            }
          }
          for (let i = 0; i < this.claims.length; i += 1) {
            if (this.claims[i].claimType === 'gkc') {
              this.goldTotal += this.claims[i].goldCoins
            }
          }
          for (let i = 0; i < this.claims.length; i += 1) {
            if (this.claims[i].claimType === 'fighter') {
              this.fighterTotal += this.claims[i].fighter.value
            }
          }
          this.getRates()
          this.loadingTable = false
        })
    },
    getExchange(ticker) {
      const currencyList = this.currencies

      return currencyList.findIndex(element => {
        if (element.ticker === ticker) {
          return true
        }

        return false
      })
    },
    async getCurrencies() {
      await dbFunctions.getCurrencies()
        .then(currencyList => {
          this.currencies = currencyList
        })
    },
    getRates() {
      const usd = this.currencies[this.getExchange('USD')].exchange
      const sol = this.currencies[this.getExchange('SOL')].exchange
      const GKC = this.currencies[this.getExchange('GKC')].exchange

      // convert to silver first to get exchange rates
      this.silverToUSD = this.silverTotal * usd
      this.silverToSOL = this.silverToUSD * sol
      this.goldToUSD = (this.goldTotal / GKC) * usd
      this.goldToSOL = this.goldToUSD * sol
    },
  },
}
</script>

<style lang="scss">
</style>
